<template>
  <div class="container-pc">
    <div class="carts-view Global-W">
      <div class="carts-title f-w">My Shopping Cart</div>
      <div class="carts-table-title">
        <div class="carts-table-title-item carts-table-title-good">Product Information</div>
        <div class="carts-table-title-item">Item Price</div>
        <div class="carts-table-title-item">Quantity</div>
        <div class="carts-table-title-item">Action</div>

      </div>
      <div class="carts-table-view">
        <div class="carts-table-item" v-for="(item) in ShopCart" :key="item">
          <div class="carts-table-item-checkbox">
            <van-checkbox shape="square" icon-size="14px" checked-color="#414141" v-model="item.checked"
              @click="itemChecked('item')"></van-checkbox>
          </div>
          <div class="carts-table-item-good">
            <div class="carts-item-img CURSOR" @click="handleGoInfo(item)">
              <img :src="item.proImg" alt="">
            </div>
            <div style="flex: 1 1;">
              <div style="font-size: 14px; color: #ccc">{{ item.proSeries }}</div>
              <div style="padding-right: 10px;margin: 5px 0;">{{ item.proName }}</div>
            </div>
          </div>
          <div class="carts-table-item-item">${{ item.proPrice }}</div>
          <div class="carts-table-item-item">
            <el-input-number v-model="item.proNum" @change="handleChange($event, item)" :min="1"
              label="描述文字"></el-input-number>
          </div>
          <div class="carts-table-item-item CURSOR">
            <el-popconfirm title="Confirm to delete this item?" width="230" @confirm="handleDelete(item)">
              <template #reference>
                Remove
              </template>
            </el-popconfirm>
          </div>
        </div>
      </div>


      <div class="carts-bottom">
        <div>
          <van-checkbox shape="square" icon-size="14px" checked-color="#414141" v-model="checkedAll"
            @click="itemChecked('all')">Select All</van-checkbox>
        </div>
        <div class="carts-bottom-right">
          <div class="carts-bottom-price">TOTAL：${{ (totalPrice / 100).toFixed(2) }}</div>
          <div>
            <van-button type="warning" color="#414141" class="carts-bottom-btn" @click="handleSubmitOrder"
              :disabled="isDisabled">
              <!-- <span class="t-icon t-icon-Paypal PAYAPL"></span> -->
              CHECKOUT
            </van-button>
          </div>
        </div>
      </div>


      <div class="carts-tips">
        <div class="carts-tips-title">Order FAQ</div>
        <div class="carts-tips-item">Service hotline: 400-1314-520</div>
        <div class="carts-tips-item">Online customer service (Online time 9:00-21:00 from Monday to Sunday)</div>
        <div class="carts-tips-item">If you encounter problems during the ordering process, please contact us. Because
          flowers
          have special requirements for delivery time, please log in at any time to check the logistics status after
          ordering.
        </div>
      </div>

    </div>
    <Footer />
  </div>
  <div class="container-m">
    <div class="carts-title f-w">My Shopping Cart</div>
    <div class="container-view">
      <van-swipe-cell v-for="(item, index) in ShopCart" :key="index">
        <div class="carts-item">
          <van-checkbox v-model="item.checked" checked-color="#414141" icon-size="16px" class="checkbox"
            @click="itemChecked"></van-checkbox>
          <van-card :num="item.proNum" :price="item.proPrice" :desc="item.proName" :title="item.proSeries"
            class="goods-card" :thumb="item.proImg" />
        </div>
        <template #right>
          <div class="delect" @click="handleDelete(item)">Remove</div>
        </template>
      </van-swipe-cell>
    </div>
    <van-submit-bar :price="totalPrice" button-text="CHECKOUT" @submit="handleSubmitOrder" currency="$"
      button-color="#414141">
      <van-checkbox v-model="checkedAll" checked-color="#414141" icon-size="16px" @click="itemChecked('all')">Select
        All</van-checkbox>
    </van-submit-bar>
  </div>

</template>

<script name="Carts" setup>
import { onActivated, onMounted, ref, watch } from "vue";
import requestAsync from "../../utils/request";
import Footer from "../../components/header/Footer.vue";
// import { useStore } from 'vuex'
import Cookies from 'js-cookie'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage, ElLoading } from 'element-plus'


const totalPrice = ref(0)
const checkedAll = ref(false)
const ShopCart = ref([])
// const store = useStore()
const route = useRoute()
const router = useRouter()

const isSubmitPayPal = ref(true)
const isDisabled = ref(true)

const loadingInstance = ref(null)

watch(totalPrice, () => {
  if (totalPrice.value > 0) {
    isDisabled.value = false
  } else {
    isDisabled.value = true
  }
})


onMounted(() => {
  getMyCartData();
})
onActivated(() => {
  getMyCartData();
})

const handleSubmitOrder = async () => {
  if (!Cookies.get('uid')) {
    router.replace({
      name: 'Login',
      query: { redirect: route.fullPath }
    })
    return
  }

  if (!isSubmitPayPal.value) return
  isSubmitPayPal.value = false

  const proList = []
  ShopCart.value.forEach((item) => {
    if (item.checked) {
      proList.push(item)
    }
  })
  Cookies.set('shopping', JSON.stringify(proList))
  router.push({
    name: 'Order',
  })
}


const getMyCartData = async () => {
  const shopCart = JSON.parse(Cookies.get('shopCart') || '[]')
  ShopCart.value = initData(shopCart)
  console.log(ShopCart.value)

}

const onSubmit = () => { };
const initData = (data) => {
  if (data && data.length > 0) {
    data.forEach((item) => {
      item.checked = false;
    });
  }
  return data;
};
const itemChecked = (type) => {
  const checkedList = [];
  ShopCart.value.forEach((item) => {
    if (type == "all") {
      item.checked = checkedAll.value
    }
    checkedList.push(item.checked);
  });

  if (checkedList.indexOf(false) === -1) {
    checkedAll.value = true;
  } else {
    checkedAll.value = false;
  }

  /* 计算价格 */
  totalPriceChange()
};

const totalPriceChange = () => {
  totalPrice.value = 0
  ShopCart.value.forEach(item => {
    if (item.checked) {
      totalPrice.value += item.proPrice * item.proNum;
    }
  })
  totalPrice.value = totalPrice.value * 100
}


const handleChange = (currentValue, item) => {
  item.proNum = currentValue
  totalPriceChange()
  Cookies.set('shopCart', JSON.stringify(ShopCart.value))
}

const handleDelete = (info) => {
  ShopCart.value.forEach((item, index) => {
    if (info.proCode === item.proCode) {
      ShopCart.value.splice(index, 1)
    }
  })
  Cookies.set('shopCart', JSON.stringify(ShopCart.value))
  totalPriceChange()
}

const handleGoInfo = (item) => {
  console.log(item)
  router.push({
    name: "Info",
    params: {
      id: item.proCode,
    },
  });
}


</script>

<style lang="scss" scoped>
.container-pc {
  .carts-title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
  }

  .carts-table-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 15px;
    padding: 0 40px;
    background-color: #f1f1f1;


    .carts-table-title-item {
      width: 20%;
    }

    .carts-table-title-good {
      width: 40%;
    }
  }

  .carts-table-view {
    width: 100%;
    padding: 20px 0;

    .carts-table-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 20px 40px;
      border-bottom: 1px solid #eee;

      .carts-table-item-item {
        width: 20%;
      }

      .carts-table-item-checkbox {
        width: 5%;
      }

      .carts-table-item-good {
        display: flex;
        align-items: center;
        width: 35%;

        .carts-item-img {
          width: 100px;
          height: 100px;
          background-color: #f5f6f7;
          margin-right: 20px;
        }
      }
    }
  }

  .carts-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 40px;
    align-items: center;

    .carts-bottom-right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .carts-bottom-price {
        margin-right: 40px;
      }

      .carts-bottom-btn {
        width: 160px;
      }
    }
  }

  .carts-tips {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 15px;
    padding: 40px;
    background-color: #f1f1f1;
    margin-bottom: 40px;

    .carts-tips-title {
      font-size: 15px;
      margin-bottom: 10px;
    }

    .carts-tips-item {
      font-size: 14px;
    }
  }
}

.container-m {
  .van-submit-bar {
    bottom: 0.9rem;
  }

  .goods-card {
    width: 100%;
  }

  .carts-title {
    width: 100%;
    height: 1rem;
    text-align: center;
    line-height: 1rem;
    font-size: .3rem;
  }


  .container-view {
    height: 75vh;
    background-color: #f7f8fa;
    padding: .3rem;
    overflow-y: scroll;


    .carts-item {
      display: flex;
      width: 100%;

      .checkbox {
        width: 0.35rem;
        margin-left: 0.1rem;
      }
    }

    .delect {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1rem;
      height: 100%;
      color: #fff;
      background-color: #ee0a24;
      font-size: 0.25rem;
    }
  }

}
</style>